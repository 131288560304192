import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { colors } from "./Layout/colors"
import dateFormat from 'dateformat';

const Display = styled.div`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: ${({ firstPost }) => (firstPost ? "column" : "row")};
    justify-content: ${({ firstPost }) =>
      firstPost ? "unset" : "space-between"};
    align-items: ${({ firstPost }) => (firstPost ? "stretch" : "center")};
    width: ${({ firstPost }) => (firstPost ? "50%" : "100%")};
    max-width: ${({ firstPost }) => (firstPost ? "608px" : "580px")};
  }

  @media (min-width: 1280px) {
    margin-bottom: 24px;
  }
`

const Cover = styled.div`
  width: 108px;
  height: 104px;
  border-radius: 16px;
  overflow: hidden;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 768px) {
    width: 100%;
    height: 100%;
    max-width: 712px;
    max-height: 456px;
    margin-right: 24px;
    flex-grow: 2;
  }

  @media (min-width: 1024px) {
    max-width: ${({ firstPost }) => (firstPost ? "100%" : "208px")};
    height: ${({ firstPost }) => (firstPost ? " 100%" : "208px")};
    margin-right: ${({ firstPost }) => (firstPost ? 0 : "24px")};
    max-height: 384px;
    min-width: 208px;
  }
`

const PostInfo = styled.div`
  @media (min-width: 1024px) {
    display: ${({ firstPost }) => (firstPost ? "flex" : "block")};
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
`

const Title = styled.h5`
  margin: ${({ firstPost }) => (firstPost ? "24px 0 0" : 0)};
  &::hover,
  &:focus,
  &:active {
    color: ${colors.bg.base};
  }
`

const Excerpt = styled.p`
  line-height: 150%;
  margin: ${({ firstPost }) => (firstPost ? "16px 0" : "10px 0")};
`

const Date = styled.p`
  line-height: 150%;
  font-size: 14px;
  margin: 0;
  color: ${colors.text.field};
`

const LinksContainer = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
  }
`

const ReadMore = styled(Link)`
  line-height: 150%;
  color: ${colors.bg.baseD};
  border-bottom: 4px solid ${colors.bg.baseD};
  padding-bottom: 8px;

  &:focus,
  &:hover,
  &:active {
    color: ${colors.bg.base};
    border-bottom-color: ${colors.bg.base};
  }
`

export default function BlogPostDisplay4({
  image,
  title,
  slug,
  date,
  excerpt,
  firstPost,
}) {
  return (
    <Display firstPost={firstPost}>
      <Cover firstPost={firstPost}>
        <GatsbyImage image={getImage(image)} alt={title} />
      </Cover>
      <PostInfo firstPost={firstPost}>
        <Link to={slug}>
          <Title firstPost={firstPost}>{title}</Title>
        </Link>
        <Excerpt firstPost={firstPost}>{excerpt}</Excerpt>
        <Date>{dateFormat(date, 'mmmm dS, yyyy')}</Date>

        {firstPost ? (
          <LinksContainer>
            <ReadMore to={slug}>Read More</ReadMore>
          </LinksContainer>
        ) : (
          ""
        )}
      </PostInfo>
    </Display>
  )
}
