import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/seo"
import BlogHero from "../components/BlogHero"
import BlogPostDisplay from "../components/BlogPostDisplay"
import BlogPostDisplay4 from "../components/BlogPostDisplay4"
import styled from "styled-components"
import { colors } from "../components/Layout/colors"
import dotPattern from "../images/dot-pattern.svg"

const BlogPage = styled.div`
  background-color: ${colors.bg.lightB};
  position: relative;
  @media (min-width: 1024px) {
    background: none;
    &::before {
      content: "";
      position: absolute;
      left: 5px;
      top: 150px;
      width: 116px;
      height: 116px;
      background: url(${dotPattern}) no-repeat;
      z-index: 999;
    }

    &::after {
      content: "";
      position: absolute;
      right: -24px;
      top: 1000px;
      width: 116px;
      height: 116px;
      background: url(${dotPattern}) no-repeat;
      z-index: 999;
    }
  }
`

const DesktopFourPosts = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 72px;
  }

  @media (min-width: 1280px) {
    gap: 24px;
  }
`

const PostsContainer = styled.div`
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding: 32px;

  @media (min-width: 1280px) {
    padding: 40px 104px;
  }
`

const LoadMore = styled.button`
  padding: 16px 32px;
  margin: 0 auto;
  display: block;
`

export default function Blog() {
  let allPosts = useStaticQuery(graphql`
    {
      allMdx(
        filter: { fields: { collection: { eq: "blog" } } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        nodes {
          excerpt
          frontmatter {
            title
            slug
            date
            category
            cover {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `)

  allPosts = allPosts.allMdx.nodes

  const [numDisplayedPosts, setNumDisplayedPosts] = React.useState(7)

  return (
    <BlogPage>
      <Layout darkText>
        <Seo title="Blog" />
        <BlogHero />
        <PostsContainer>
          <DesktopFourPosts>
            {allPosts.slice(0, 1).map((post, i) => (
              <BlogPostDisplay4
                key={post.frontmatter.slug}
                image={post.frontmatter.cover}
                title={post.frontmatter.title}
                slug={post.frontmatter.slug}
                date={post.frontmatter.date}
                category={post.frontmatter.category}
                excerpt={post.excerpt}
                firstPost
              />
            ))}
            <div>
              {allPosts.slice(1, 4).map((post, i) => (
                <>
                  <BlogPostDisplay4
                    key={post.frontmatter.slug}
                    image={post.frontmatter.cover}
                    title={post.frontmatter.title}
                    slug={post.frontmatter.slug}
                    date={post.frontmatter.date}
                    excerpt={post.excerpt}
                  />
                </>
              ))}
            </div>
          </DesktopFourPosts>

          {allPosts.slice(4, numDisplayedPosts).map((post, i) => (
            <BlogPostDisplay
              key={post.frontmatter.slug}
              image={post.frontmatter.cover}
              title={post.frontmatter.title}
              slug={post.frontmatter.slug}
              date={post.frontmatter.date}
              category={post.frontmatter.category}
              excerpt={post.excerpt}
            />
          ))}
          {allPosts.length > numDisplayedPosts && (
            <LoadMore onClick={() => setNumDisplayedPosts(posts => posts + 3)}>
              Load More Articles
            </LoadMore>
          )}
        </PostsContainer>
      </Layout>
    </BlogPage>
  )
}
