import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { colors } from "./Layout/colors"
import dateFormat from 'dateformat';

const Display = styled.div`
  margin-bottom: 40px;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (min-width: 1160px) {
    align-items: stretch;
  }
`

const Cover = styled.div`
  width: 108px;
  height: 104px;
  border-radius: 16px;
  overflow: hidden;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 768px) {
    width: 100%;
    height: 100%;
    max-width: 712px;
    max-height: 456px;
    margin-right: 24px;
    flex-grow: 2;
  }

  @media (min-width: 1024px) {
    margin-right: 40px;
  }
`

const Title = styled.h5`
  margin: 16px 0 8px;
  &::hover,
  &:focus,
  &:active {
    color: ${colors.bg.base};
  }
`

const PostDetails = styled.div`
  @media (min-width: 1160px) {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }
`

const PostInfo = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`

const InfoContainer = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 1024px) {
    order: -1;
  }

  @media (min-width: 1160px) {
    margin-top: 16px;
  }
`

const Date = styled.p`
  line-height: 150%;
  font-size: 14px;
  margin: 0;
  color: ${colors.text.field};

  @media (min-width: 1024px) {
    margin-left: ${({ category }) => (category ? "32px" : 0)};
  }
`

const Category = styled.p`
  font-weight: 700;
  color: ${colors.text.title};
  display: none;
  margin: 0;
  text-transform: capitalize;

  @media (min-width: 1024px) {
    display: block;
  }
`

const LinksContainer = styled.div`
  display: none;

  @media (min-width: 1160px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
`

const ReadMore = styled(Link)`
  line-height: 150%;
  color: ${colors.bg.baseD};
  border-bottom: 4px solid ${colors.bg.baseD};
  padding-bottom: 8px;

  &:focus,
  &:hover,
  &:active {
    color: ${colors.bg.base};
    border-bottom-color: ${colors.bg.base};
  }
`

export default function BlogPostDisplay({
  image,
  title,
  slug,
  date,
  category,
  excerpt,
}) {
  return (
    <Display>
      <Cover>
        <GatsbyImage image={getImage(image)} alt={title} />
      </Cover>
      <PostDetails>
        <PostInfo>
          <Link to={slug}>
            <Title>{title}</Title>
          </Link>
          <p style={{ lineHeight: "150%" }}>{excerpt}</p>
          <InfoContainer>
            <Category>{category}</Category>
            <Date category={category}>{dateFormat(date, 'mmmm dS, yyyy')}</Date>
          </InfoContainer>
        </PostInfo>

        <LinksContainer>
          <ReadMore to={slug}>Read More</ReadMore>
        </LinksContainer>
      </PostDetails>
    </Display>
  )
}
