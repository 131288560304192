import React from "react"
import styled from "styled-components"
import { colors } from "./Layout/colors"
import HeroBg from "../images/mobile-hero-bg.svg"
import HeroBgBig from "../images/mobile-hero-bg-big.svg"

const Hero = styled.section`
  position: relative;
  padding: 136px 32px 48px;
  background-image: url(${HeroBg});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;

  @media (min-width: 580px) {
    background-image: url(${HeroBgBig});
  }

  @media (min-width: 1024px) {
    padding: 160px 32px 32px 104px;
    background: none;
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 1160px) {
    padding: 160px 104px 32px 0;
    margin-left: auto;
    max-width: 1056px;
  }

  @media (min-width: 1280px) {
    padding: 160px 0 32px 0;
    margin-right: 104px;
    max-width: 1024px;
  }
`

const Title = styled.h4`
  margin-top: 24px;
  margin-bottom: 24px;
  color: ${colors.text.white};
  text-transform: capitalize;

  @media (min-width: 1024px) {
    display: none;
  }
`

export default function BlogPostHero() {
  return (
    <Hero>
      <Title>Blog</Title>
    </Hero>
  )
}
